.topnav {
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: var(--main-bg);
  color: var(--secondary-main-color);
  cursor: pointer;
}

.topnav__left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topnav__logo {
  /* width: 60px; */
  height: 60px;
  object-fit: cover;
  transition: all 0.6s var(--transition-cubic);
  margin-right: 10px;
}
.topnav__logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.nav__menu {
  display: flex;
  align-items: center;
  color: var(--text-white);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  background-color: transparent;
  text-transform: capitalize;
  font-size: 1.3rem;
  text-decoration: none;
  float:right;
}

.nav__menu li {
  list-style-type: none;
  cursor: pointer;
  margin-right: 1rem;
}
.nav__menu li:hover {
  color: var(--primary-main-color);
}
.menubar {
  padding: 5px 10px;
  display: none;
  align-items: center;
  justify-content: space-between;
  height: var(--topnav-height);
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: var(--main-bg);
  color: var(--secondary-main-color);
  cursor: pointer;
}
.menubar__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar__logo {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media only screen and (max-width: 918px) {
  .nav__menu {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
  .topnav {
    padding: 0px 15px;
    height: var(--topnav-height);
    display: none;
  }
  .topnav__logo {
    display: none;
  }
  .menubar {
    display: block;
  }
}
.backIcon {
  /* border: 1px solid black; */
  /* width: 70px;
  height: 70px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 50%; */
}

.backIcon__logo {
  height: 47px;
  width: 47px;
  object-fit: cover;
  transition: all 0.6s var(--transition-cubic);
  /* margin-right: 10px; */
}
.backIcon__logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
