.blog {
  background-color: transparent;
  margin: auto;
  padding: 50px 0 0;
  padding-bottom: 3rem;
}
.news__list__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  position: relative;
}
.card__actionarea {
  position: relative;
  height: 100%;
  display: grid !important;
  grid-template-columns: 1fr;
}
.card__actionarea:hover .image {
  transform: scale(1.2);
  transition: transform 330ms ease-in-out;
}
@media only screen and (max-width: 918px) {
  .news__list__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 700px) {
  .news__list__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 480px) {
  .news__list__wrapper {
    grid-template-columns: 1fr;
  }
}
