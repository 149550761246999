.service {
  min-height: calc(90% - var(--topnav-height));
  background-color: transparent;
  margin: auto;
  padding: 50px 0 0;
}

@media only screen and (max-width: 918px) {
  .service {
    padding: 50px 0 0;
  }
}

.service_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media only screen and (max-width: 700px) {
  .service_list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .service_list {
    grid-template-columns: 1fr;
  }
}

.service__icons {
  background-color: #edeff1;
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;
  color: var(--secondary-main-color);
  font-style: italic;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.service__values:hover .service__icons {
  background-color: var(--secondary-main-color);
  color: white;
}
.service__values {
  display: flex;
  gap: 10px;
  align-items: center;
  /* margin-bottom: 20px; */
}

.service__values h4 {
  color: var(--secondary-main-color);
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: var(--secondary-main-color);
}
.content {
  display: flex;
  align-items: center;
  gap: 10px;
}
