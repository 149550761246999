$media-tablet: "only screen and (max-width :  918px)";
$media-desktop: "only screen and (max-width : 1024px)";

$media-mobile: "only screen and (max-width : 600px)";
$media-mobile-sm: "only screen and (max-width : 480px)";
.topnews {
  margin-bottom: 3%;
  padding: 20px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  @media #{$media-tablet} {
    padding: 15px;
  }
}

.detailNewsHolder {
  width: 80%;
  margin: 0 auto 3%;
  padding: 30px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  @media #{$media-tablet} {
    padding: 15px;
    width: 90%;
  }
}
.bottomNews {
  display: inline-flex;
}
.newsBody {
  text-align: justify;
}
.news-headImage {
  object-fit: cover;
  width: 100%;
  max-height: 500px;
  margin-bottom: 10px;
  @media #{$media-small} {
    object-fit: cover;
  }
}
.news {
  width: 80%;
  margin: 0 auto 3%;
  padding: 30px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  @media #{$media-small} {
    padding: 15px;
    width: 90%;
  }
}
.company__news {
  width: 80%;
  margin: 0 auto 15px;
  padding: 10px 20px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  @media #{$media-small} {
    padding: 10px;
    width: 90%;
  }
}

.news__list__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  position: relative;
  @media #{$media-small} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media #{$media-mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
}

.news__body {
  position: relative;
  height: 100%;
  display: grid !important;
  grid-template-columns: 1fr;
  &:hover .image {
    transform: scale(1.2);
    transition: transform 330ms ease-in-out;
  }
}

.adAndNews {
  display: grid;
  grid-template-columns: 13% 1fr 13%;
  gap: 15px;
  padding: 15px;

  @media #{$media-tablet} {
    display: block;
  }
}
// .sideahholder{
//   background-color: aqua;
//   width: 180px;
// }
.sideads {
  // background-image: url("../sidead.jpg")
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center;
  height: 330px;
  margin-bottom: 10px;
  text-decoration: none;
  
}
.topNav{
  background-color: aquamarine;
  color: blue;
}

