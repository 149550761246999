$media-tablet: "only screen and (max-width :  918px)";
.breadcrumb {
  width: fit-content;
  box-shadow: #959da533 0px 8px 24px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: white;
  margin: 20px 10%;
  @media #{$media-tablet} {
    margin: 20px 5%;
  }
}
.breadcrumbParent {
  width: 90%;
  margin-bottom: 20px;
  margin-right: 100px;
  // box-shadow: 0 2px 10px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
  // border-bottom: 1px brown solid;
}
