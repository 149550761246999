@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif !important;
  /* font-weight: 400; */
}
:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --primary-main-color: rgba(244, 151, 3, 0.8);
  --primary-light-color: rgba(244, 151, 3, 0.8);
  --secondary-main-color: rgb(20, 61, 89);
  --secondary-light-color: rgba(20, 61, 89, 0.8);
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --sidebar-width: 270px;
  --border-radius: 15px;
  --topnav-height: 70px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --table-hover: #f83245;
}
::-webkit-scrollbar-track {
  box-shadow: inset rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(218, 227, 235, 0.15) 0px 1px 3px 1px;
  -webkit-box-shadow: inset rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(218, 227, 235, 0.15) 0px 1px 3px 1px;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset rgba(5, 5, 5, 0.3) 0px 1px 2px 0px,
    rgba(218, 227, 235, 0.15) 0px 1px 3px 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(20, 61, 89);
}

body,
html {
  background-color: #fafafb;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.marker-btn {
  cursor: pointer;
  font-size: large;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #d00;
  background-color: transparent !important;
  outline: none;
  border: none;
}
.navigation-control {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
}
.map__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.current__location {
  position: absolute !important;
  right: 1rem !important;
  bottom: 2rem !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  color: rgb(20, 61, 89);
  outline: none;
  border: none;
  cursor: pointer;
}
.detail .row1 {
  display: flex;
  flex-wrap: row wrap;
  column-gap: 10px;
  align-items: center;
}
.detail .row2 {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  align-items: center;
}

.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}
.mr-2 {
  margin-right: 1rem !important;
}

@media only screen and (max-width: 918px) {
  .col-md-1 {
    width: 8.33%;
  }

  .col-md-2 {
    width: 16.66%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33%;
  }

  .col-md-5 {
    width: 41.66%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33%;
  }

  .col-md-8 {
    width: 66.66%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33%;
  }

  .col-md-11 {
    width: 91.66%;
  }

  .col-md-12 {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .col-sm-1 {
    width: 8.33%;
  }

  .col-sm-2 {
    width: 16.66%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33%;
  }

  .col-sm-5 {
    width: 41.66%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33%;
  }

  .col-sm-8 {
    width: 66.66%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33%;
  }

  .col-sm-11 {
    width: 91.66%;
  }

  .col-sm-12 {
    width: 100%;
  }
}
.card {
  padding: 30px;
  margin: 0px 0px 30px 0px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}
.page-header {
  margin-bottom: 30px !important;
  text-transform: capitalize;
  color: rgb(20, 61, 89);
}
.catagory__filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  gap: 20px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  color: " rgb(20, 61, 89)";
}
.load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: " rgb(20, 61, 89)";
}
html {
  scroll-behavior: smooth;
}
.backtotop {
  position: fixed !important;
  right: 1rem !important;
  bottom: 2rem !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  border-radius: 10px;
  background: rgb(20, 61, 89);
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  transform: rotate(-90deg);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
.content {
  height: fit-content !important;
  /* border: 5px solid black; */
  overflow-y: auto;
}
.register__company,
.updateCompanyForm,
.newsform {
  width: 80%;
  margin: 0 auto 3%;
  padding: 30px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
}
.ql-editor {
  max-height: fit-content;
  min-height: 250px;
}
@media only screen and (max-width: 918px) {
  .register__company,
  .updateCompanyForm,
  .newsform {
    padding: 15px;
    width: 90%;
  }
}
.accordionSummery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detail__page {
  /* min-height: 100vh; */
  height: 100%;
  /* background-color: white; */
  background-color: rgba(244, 151, 3, 0.1);
  padding-bottom: 30px;
}
