.about {
  background-color: transparent;
  margin: auto;
  padding: 50px 0 0;
}

.about__content {
  text-align: justify;
  color: var(--secondary-main-color);
  font-size: 1.1rem;
}
@media only screen and (max-width: 918px) {
  .about {
    padding: 50px 0 0;
  }
}
