.contactus {
  min-height: calc(90% - var(--topnav-height));
  background-color: transparent;
  margin: auto;
  padding: 50px 0 0;
}

@media only screen and (max-width: 918px) {
  .contactus {
    padding: 50px 0 0;
  }
}
.contact__container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}
.social__links__container {
  display: flex;
  gap: 10px;
  /* align-items: center; */
  margin-bottom: 20px;
  flex-direction: column;
}
.contact__icons {
  background-color: #edeff1;
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;
  color: var(--secondary-main-color);
  font-style: italic;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.social__links__container h4 {
  font-size: 20px;
  align-items: center;
  color: var(--secondary-main-color);
  font-style: italic;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.contact__values {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.social__links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.contact__values p,
.contact__values__array p {
  color: #8795a4;
  font-size: 14px;
  margin: 0;
}
.contact__values a,
.contact__values__array a {
  color: #0d6efd;
  font-size: 14px;
  margin: 0;
  white-space: normal;
}
.contact__values h4,
.contact__values__array h4 {
  color: var(--secondary-main-color);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.contact__methods {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media only screen and (max-width: 600px) {
  .contact__methods {
    grid-template-columns: 1fr;
  }
}
.content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contact__container:hover .contact__icons {
  background-color: var(--secondary-main-color);
  color: white;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
