.CopyRightContainer
{
    width:100%;
    background-color: $color1;
    .copyRight
    {
        text-align: center;
        font-weight: bold;
        // font-size: larger;
        color:$color3;
    }
}