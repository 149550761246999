.Title {
  text-transform: uppercase;
  font-size: 2rem;
  color: var(--secondary-main-color);
  position: relative;
  padding-bottom: 1rem;
  letter-spacing: 3px;
  margin-bottom: 20px;
}
.Title span {
  position: absolute;
  top: 30%;
  left: 0;
  font-size: 3.5rem;
  opacity: 0.1;
}
.Title::before {
  content: "";
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: var(--secondary-light-color);
  width: 6rem;
  height: 0.4rem;
  border-radius: 50px;
}
.Title::after {
  content: "";
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: var(--secondary-main-color);
  width: 3rem;
  height: 0.4rem;
  border-radius: 50px;
}
