$media-medium: "only screen and (max-width : 820px)";
$media-small: "only screen and (max-width : 918px)";
$media-mobile: "only screen and (max-width : 600px)";
$media-desktop: "only screen and (max-width : 1024px)";
$media-tablet: "only screen and (max-width : 768px)";
$media-xs-small: "only screen and (max-width : 398px)";
.categoriesList {
  position: absolute;
  list-style: none;
  line-height: 30px;
  background-color: white;
  // min-width: 100px;
  margin-top: 12px;
  // border: darkgrey 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // box-shadow: 0 2px 10px 8px rgba(0, 0, 0, 0.2);

  .categoriesListA {
    flex: 33%;
    background-color: white;
    border: darkgrey solid 1px;
    height: auto;
    padding: 20px;
    z-index: 1001;
    cursor: pointer;
    transition-delay: 2s, 4ms;
    min-height: 200px;

    li {
      list-style: none;
      padding: 5px;
      color: black;
    }
  }
  .categoriesListB {
    flex: 33%;
    border-right: darkgrey solid 1px;
    border-bottom: darkgrey solid 1px;
    border-top: darkgrey solid 1px;
    background-color: white;
    padding: 20px;
    z-index: 1001;
    cursor: pointer;
    transition-delay: 2s, 4ms;

    li {
      list-style: none;
      padding: 5px;
      color: black;
    }
  }
  .categoriesListC {
    flex: 33%;
    border: darkgrey solid 1px;
    border-left: white solid 1px;
    background-color: white;
    padding: 20px;
    z-index: 1001;
    cursor: pointer;
    transition-delay: 2s, 4ms;
    min-height: 200px;

    li {
      list-style: none;
      padding: 5px;
      color: black;
    }
  }
}
.mobileCategoryList {
  line-height: 5vh;
  margin-left: 2vh;
}
.catagory__wrapper {
  width: 95%;
  margin: 0 auto 3%;
  padding: 30px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  overflow-y: auto !important;
  @media #{$media-small} {
    padding: 15px;
  }
}
.catagory__card {
  height: 15rem;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.blurBackground {
  filter: blur(4px);
  position: absolute;
  width: 100%;
  height: 100%;
}
.catagory__list {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;

  @media #{$media-desktop} {
    grid-template-columns: repeat(5, 1fr);
  }
  @media #{$media-medium} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media #{$media-mobile} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media #{$media-xs-small} {
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    position: relative;
    height: 15rem;
    background-color: transparent;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    backdrop-filter: blur(5px);
    @media #{$media-small} {
      height: 15rem;
    }
  }

  .card__inner {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    opacity: 1;
    padding: 10px 0rem;
    transition: all 0.4s ease 0s;
    color: white;
    text-align: center;

    background-color: rgba(20, 61, 89, 0.8);
    @media #{$media-mobile} {
      font-weight: bold !important;
      opacity: 1;
      background-color: rgba(20, 61, 89, 0.8);
    }
  }

  .card:hover .card__inner {
    opacity: 1;
  }
}
.listWidth {
  width: fit-content !important;
  max-height: 80vh;
  height: fit-content !important;
  overflow-y: auto;
  // position: absolute;
  // top: 0;
  // right: 0;
}
