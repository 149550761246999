.CPntn {
  border: none;
  // background-color:rgb(244,151,3);
  border-radius: 10px;
  color: rgb(20, 61, 89);
  font-weight: bold;
  font-size: large;
  cursor: pointer;
  margin-left: 30px;
  padding: 2vh;
}
.CPbtn_card {
  border: none;
  background-color: white;
  box-shadow: 0 2px 10px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.maintitle {
  .title {
    // padding-left: 20%;
    color: rgb(20, 61, 89);
    font-weight: bold;
    margin-top: 2vh;
    ul {
      li {
        display: inline-block;
      }
    }
  }
}
ul {
  li {
    list-style: none;
  }
}
.icon {
  width: 100%;

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: rgb(244, 151, 3);
    font-size: 25px;
  }
}
.cursor {
  cursor: pointer;
}
.mb-8 {
  margin-bottom: 2rem;
}
// label {
//   font-weight: bold;
//   color: rgb(20, 61, 89);
//   margin: 1vh;
// }
label {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 600;
  color: rgb(151, 150, 150);
  display: block !important;
}
.textAnimation:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
.button {
  padding: 2vh;
  color: rgb(225, 225, 225);
  background-color: rgb(20, 61, 89);
  padding: 2vh;
  width: 15vh;
  font-weight: bold;
  border: none;
  border-radius: 10%;
}
.Button-Link {
  text-decoration: none;
  color: rgb(20, 61, 89);
  font-weight: bold;
}
.center {
  text-align: center;
}
li {
  color: rgb(20, 61, 89);
  font-weight: bold;
}
.icon {
  color: rgb(244, 151, 3);
}

$color1: rgb(244, 151, 3);
$colorOp: rgba(244, 151, 3, 0.83);
$color2: rgb(225, 225, 225);
$color3: rgb(20, 61, 89);
$color4: rgb(255, 255, 255);
$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
$bg-color: #fafafb;
