
.profile {
    // padding: 1em 2em;
  
    .image {
    //   @include flex;
      justify-content: center;
    //   background: $color7;
      padding-top: 3em;
      padding-bottom: 0.3em;
      //  border-radius:0 0 100% 50%;
      position: relative;
      // background:#000;
      height: 200px;
      text-align: center;
      img {
        width: 9em;
        height: 9em;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        position: absolute;
        background: white;
        border-radius: 50%;
        bottom: -4em;
        left: 50%;
  
        margin-top: -4.5em; /* Half the height */
        margin-left: -4.5em;
      }
    }
    .profile-btn {
    //   color: $color7;
      margin: 0 3em;
      cursor: pointer;
    }
    .edit-profile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      // border:1px solid red;
  
      border-radius: 0.8em;
      // background: #ffad333b;
      width: max-content;
      margin: 0.4em auto;
      padding: 0.7em;
    }
    .edit-profile-mobile {
      flex-direction: column;
    }
    .delete-photo {
    //   @include flex;
      margin-top: 4.5em;
      justify-content: center;
    //   color: $color7;
    }
    .name {
    //   @include flex;
      margin-top: 0.2em;
      justify-content: center;
      font-size: 1.8em;
      font-weight: bold;
    }
    .contact-list {
      // border:1px solid red;
  
      width: 60%;
      margin: 2em auto;
    //   color: $color7;
      margin-top: 4em;
    //   @include flex;
      justify-content: space-around;
      .contact-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
          margin-bottom: 0.5em;
        }
      }
    }
    .contact-list-mobile {
      flex-direction: column;
    }
    .dashboard {
      width: 90%;
      margin: 4em auto;
      // border:1px solid red;
      .item {
        margin-top: 1.5em;
      }
      .history {
        // @include box;
        .header {
        //   @include profileHeaderStyle()
        }
      }
      .cart {
        
        .header {
        
        }
        .content {
          padding: 0.75em;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .wishlist {
        
        .header {
    
        }
        .content {
          padding: 0.75em;
          display: flex;
          align-items: center;
          justify-content: center;
          h1 {
            margin: 0 0.2em;
          }
        }
      }
    }
  }
  .edit-profile-modal {
  
  
    &::-webkit-scrollbar {
      width: 7px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .edit-profile-content {
      form {
        // border:1px solid green;
        display: flex;
        flex-direction: column;
        color: #666;
        margin: 0em 1em 1em 1em;
        padding: 0em 1em 1em 1em;
        .form-grp {
          display: flex;
          flex-direction: column;
          margin-top: 1em;
          img {
            border-radius: 50%;
          }
          .input {
            padding: 0.5em 1em;
            border: 1px solid #ccc;
            border-radius: 30px;
  
            &:focus {
              outline: none;
              border: 1px solid black;
              border-radius: 30px;
            }
          }
          .btn {
           
          }
          .form-error {
            color: red;
            margin-top: 0.5em;
          }
          .loading {
            display:flex;
            justify-content: center;
            margin-bottom: 0.4em;
          }
        }
      }
    }
  }