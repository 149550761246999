@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page {
  @include center;
  width: 100%;
  height: 100vh;
  background-image: url("./../hero.jpg");
  background-size: cover;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 100;
  color: white !important;
}

.notfoundcontent {
  // background:rgba(255, 255, 255, 0.3);
  text-align: center;
  padding-top: 118px;
  //   color: rgb(20, 61, 89);
  height: 100%;
  .backtohome {
    display: inline-block;
    text-transform: uppercase;
    border: 2px solid white;
    padding: 15px 25px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    font-size: 20px;
    line-height: 1;
    &:hover {
      color: rgb(20, 61, 89);
      border: 2px solid rgb(20, 61, 89);
    }
  }
  h1 {
    font-weight: 900;
    font-size: 165px;
    line-height: 1;
    margin-bottom: -10px;
    // opacity: 0.6;
  }

  h2 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 6px;
    // opacity: 0.9;
  }

  p {
    font-weight: 300;
    font-size: 20px;
    // opacity: 0.7;
    margin-bottom: 40px;
  }
}

.page img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  transform: scale(1.1);
}
